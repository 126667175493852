import logo from './logo.jpg';
import './App.css';
import {
  BrowserRouter as Router,
  Redirect,
  Switch,
  Route,
} from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import FanView from './views/Fan';
import StoreView from './views/Store';
import OrderStatus from './views/Fan/OrderStatusPage';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const Home = () => (
  <header className="App-header">
    <img src={logo} className="App-logo" alt="logo" />
    <h3>Coming Soon!</h3>
  </header>
);

const RedirectOldRoutes = () => {
  const ids = window.location.pathname.split('/');
  const organizationUid = ids[2];
  const sectionId = ids[6];
  const rowId = ids[8];
  const seatId = ids[10];

  return (
    <Redirect
      to={`/venues/${mapOrgToVenue(
        organizationUid,
      )}/section/${sectionId}/row/${rowId}/seat/${seatId}`}
    />
  );
};

const mapOrgToVenue = (organizationUid) => {
  const venueMap = {
    bc2005: 'abc123',
    nashvillechristian: 'nashchristian',
    providencechristian: 'provchristian',
    ponderosa: 'ponderosa',
    vanderbilt: 'vanderbilt',
    msstate: 'msstate-football',
    ofallon: 'carshield',
  };

  return venueMap[organizationUid];
};

console.log('new version 4');

function App() {
  return (
    <div className="App">
      <Elements stripe={stripePromise}>
        <Router>
          <Switch>
            {/* <Route path="/organization/:organizationUid/*">
              <RedirectOldRoutes />
            </Route> */}
            <Route path="/hoots">
              <Redirect to="/venues/hoots" />
            </Route>
            <Route
              exact
              path="/venues/:venueUid/suites/:suiteId/promos/:promoUid"
              component={FanView}
            />
            <Route
              exact
              path="/venues/:venueUid/tables/:tableId/promos/:promoUid"
              component={FanView}
            />
            <Route
              exact
              path="/venues/:venueUid"
              component={FanView}
            />
            <Route
              exact
              path="/venues/:venueUid/suites/:suiteId"
              component={FanView}
            />
            <Route
              exact
              path="/venues/:venueUid/tables/:tableId"
              component={FanView}
            />
            <Route
              exact
              path="/venues/:venueUid/section/:sectionId/row/:rowId/seat/:seatId"
              component={FanView}
            />
            <Route
              exact
              path="/stores/:storeUid"
              component={StoreView}
            />
            <Route
              exact
              path="/orders/:orderUid"
              component={OrderStatus}
            />
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </Router>
      </Elements>
    </div>
  );
}

export default App;
