import React from 'react';
import { Grid } from '@material-ui/core';
import MenuItemCard from './MenuItemCard';

const MenuItemList = ({
  menuItems,
  cartItems,
  setCartItems,
  setSnackbarOpen,
}) => {
  const styles = {
    menuItemList: {
      minHeight: '350px',
      paddingBottom: '65px',
    },
  };
  return (
    <Grid
      container
      justify="center"
      style={styles.menuItemList}
      spacing={1}
    >
      {menuItems.map((menuItem) => {
        return (
          <Grid item xs={12} md={6} key={menuItem.id}>
            <MenuItemCard
              menuItem={menuItem}
              cartItems={cartItems}
              setCartItems={setCartItems}
              setSnackbarOpen={setSnackbarOpen}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default MenuItemList;
