const fetchStore = async (storeUid) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/stores/${storeUid}`;
    const response = await fetch(url, {
      headers: {
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMDE1NyIsIm5hbWUiOiJDb25jZXNzaW9ubHkiLCJpYXQiOjE1MTYyMzkwMjJ9.YVx_aeq905WEf8SGP4r8ZpYmNzklvxpM1yYzkYfOqL0',
        'Content-Type': 'application/json',
      },
    });
    const json = await response.json();
    return json;
  } catch (error) {
    console.error(error);
  }
};

const updateStore = async (uid, update) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/stores/${uid}`;
    const response = await fetch(url, {
      method: 'PATCH',
      headers: {
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMDE1NyIsIm5hbWUiOiJDb25jZXNzaW9ubHkiLCJpYXQiOjE1MTYyMzkwMjJ9.YVx_aeq905WEf8SGP4r8ZpYmNzklvxpM1yYzkYfOqL0',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(update),
    });
    const json = await response.json();
    return json;
  } catch (error) {
    console.error(error);
  }
};

export { fetchStore, updateStore };
