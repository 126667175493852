import React, { useEffect, useState } from 'react';
import { fetchOrder, getWaitTime } from '../../lib/orders';
import {
  Card,
  Grid,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';

const OrderStatus = (props) => {
  const { orderUid } = props.match.params;
  const [order, setOrder] = useState(null);
  const [waitTime, setWaitTime] = useState(null);
  const [alertShown, setAlertShown] = useState(false);
  const [orderComplete, setOrderComplete] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const selectedOrder = await fetchOrder(orderUid);

      setOrder({
        ...selectedOrder,
        items: JSON.parse(selectedOrder.items),
      });

      setOrderComplete(selectedOrder?.status === 'complete');

      // const calculatedWaitTime = await getWaitTime(
      //   selectedOrder.storeId,
      // );

      // setWaitTime(calculatedWaitTime);

      if (selectedOrder.status !== 'complete') {
        window.checkOrderStatus = setInterval(async () => {
          let latestOrder = await fetchOrder(orderUid);
          if (latestOrder.status === 'complete' && !alertShown) {
            alert('Your order is ready!');
            setAlertShown(true);
            setOrderComplete(true);
            clearInterval(window.checkOrderStatus);
          }
        }, 30000);
      }
    };

    fetchData();
  }, []);

  if (!order) {
    return <p>Loading...</p>;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <h2>Order #{order.id}</h2>
        {order.seatLocation && <h3>{order.seatLocation}</h3>}
        {orderComplete ? (
          <>
            <h3 style={{ color: 'green' }}>Your order is ready!</h3>
            <h3 style={{ color: 'green' }}>
              {order.seatLocation
                ? `It will be delivered shortly.`
                : `It can be picked up at the designated location.`}
            </h3>
          </>
        ) : (
          <>
            <h3>Thank you for your order!</h3>
            <h4 style={{ color: 'green' }}>
              Keep this page open for order update alerts.
            </h4>
            <p>
              Time Placed:{' '}
              {new Date(order.createdAt).toLocaleString('en-us', {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
              })}
            </p>
            {waitTime && <p>Average Order Wait Time: {waitTime}</p>}
          </>
        )}
        <Grid container>
          {order.items.map((item) => (
            <Grid item key={item.id} md={4} sm={6} xs={12}>
              <Card
                variant="outlined"
                style={{ height: '60px', padding: '20px' }}
              >
                <ListItem>
                  <img
                    src={item.image}
                    style={{
                      height: '50px',
                      width: '50px',
                    }}
                  />
                  <ListItemText
                    primary={`${item.name} x ${item.quantity}`}
                    secondary={`${item.specialInstructions}`}
                    style={{ padding: '0 10px' }}
                  />
                </ListItem>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OrderStatus;
