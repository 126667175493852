import React, { useEffect, useState } from 'react';
import {
  Grid,
  makeStyles,
  Snackbar,
  ThemeProvider,
} from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import SearchBar from '../../components/SearchBar';
import Cart from './Cart';
import MenuItemList from './MenuItemList';
import OrgCard from './OrgCard';
import { fetchVenue } from '../../lib/venues';
import { fetchStoresForSection } from '../../lib/stores';
import { demoMenuItems } from '../../lib';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const FanView = (props) => {
  const {
    promoUid,
    rowId,
    seatId,
    sectionId,
    suiteId,
    tableId,
    venueUid,
  } = props.match.params;

  const searchParams = new URLSearchParams(window.location.search);

  const [organization, setOrganization] = useState({
    primaryColor: `#${searchParams.get('primaryColor')}` || '#fff',
    secondaryColor:
      `#${searchParams.get('secondaryColor')}` || '#000',
    logo: searchParams.get('logo') || '',
    name: searchParams.get('name') || '',
    mascot: '',
  });
  const [isDemo] = useState(venueUid === 'demo');
  const [venue, setVenue] = useState({
    name: searchParams.get('venueName') || '',
  });
  const [isHermitage] = useState(
    venueUid === 'generalsretreat' ||
      venueUid === 'presidentsreserve',
  );

  const [allYouCanEat] = useState(promoUid === 'ayce2022');

  const [acceptingOrders, setAcceptingOrders] = useState(
    isDemo ? true : false,
  );

  const seatLocation =
    (suiteId && `Suite ${suiteId}`) ||
    (tableId && `Table ${tableId}-Top`) ||
    (sectionId &&
      rowId &&
      seatId &&
      `Section ${sectionId}, Row ${rowId}, Seat ${seatId}`);
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchVenue(venueUid);
      setVenue(response);
      setOrganization(response?.organization);

      // const stores = await fetchStoresForSection(
      //   selectedVenue.id,
      //   sectionId,
      // );

      let menuItems = response?.stores[0].menuItems.filter(
        (item) => item.active,
      );

      setAcceptingOrders(response?.stores[0].active);
      // stores.map(
      //   (store) => (menuItems = [...menuItems, ...store.menu_items]),
      // );

      if (allYouCanEat) {
        menuItems = menuItems
          .filter((item) => item.ayce)
          .map((item) => {
            return { ...item, price: 0 };
          });
      }

      setBaseMenuItems(menuItems);
      setFilteredMenuItems(menuItems);
    };

    if (!isDemo) {
      fetchData();
    }
  }, []);

  const [orderType, setOrderType] = useState('Pick Up Order');
  const [baseMenuItems, setBaseMenuItems] = useState(
    isDemo ? demoMenuItems : [],
  );
  const [filteredMenuItems, setFilteredMenuItems] = useState(
    isDemo ? demoMenuItems : [],
  );
  const [cartItems, setCartItems] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [allYouCanEatOpen, setAllYouCanEatOpen] =
    useState(allYouCanEat);
  const handleSearch = (searchValue) => {
    const searchResults = baseMenuItems.filter(
      (item) =>
        item.name.toLowerCase().indexOf(searchValue.toLowerCase()) >
        -1,
    );
    setFilteredMenuItems(searchResults);
  };
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: `${organization.primaryColor}`,
      },
      secondary: {
        main: `${organization.secondaryColor}`,
      },
    },
  });

  const useStyles = makeStyles((theme) => ({
    orgCard: {
      backgroundColor: `${organization.primaryColor}`,
      color: `${organization.secondaryColor}`,
      padding: '10px',
    },
    logo: {
      maxWidth: '300px',
    },
  }));

  const classes = useStyles();

  if (!organization.name > 0) {
    return <h2>Loading...</h2>;
  }

  return (
    <ThemeProvider theme={theme}>
      <Grid container>
        <Grid item xs={12}>
          <OrgCard
            classes={classes}
            organization={organization}
            seatLocation={seatLocation}
            venue={venue}
          />
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={allYouCanEatOpen}
            onClose={() => setAllYouCanEatOpen(false)}
            autoHideDuration={2000}
          >
            <Alert severity="success">
              All You Can Eat activated!
            </Alert>
          </Snackbar>
          {!acceptingOrders ? (
            <h3 style={{ color: 'red' }}>
              Mobile Ordering is not available at this time
            </h3>
          ) : (
            <>
              <SearchBar handleSearch={handleSearch} />
              <MenuItemList
                menuItems={filteredMenuItems}
                cartItems={cartItems}
                setCartItems={setCartItems}
                setSnackbarOpen={setSnackbarOpen}
              />
              <Snackbar
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                open={snackbarOpen}
                onClose={() => setSnackbarOpen(false)}
                autoHideDuration={2000}
              >
                <Alert severity="success">Items added to cart.</Alert>
              </Snackbar>
              {cartItems.length > 0 && !snackbarOpen && (
                <Cart
                  allYouCanEat={allYouCanEat}
                  cartItems={cartItems}
                  isDemo={isDemo}
                  isHermitage={isHermitage}
                  orderType={orderType}
                  pickupInstructions={venue.pickupInstructions}
                  seatLocation={seatLocation}
                  setOrderType={setOrderType}
                  taxRate={isDemo ? 0.05 : venue.taxRate}
                />
              )}
            </>
          )}
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default FanView;
