import React from 'react';
import {
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from '@material-ui/core';

const OrderCard = ({ actionText, handleAction = null, ...order }) => {
  const styles = {
    card: {
      alignItems: 'center',
      padding: '30px',
      border: '1px solid black',
      height: '300px',
    },
    items: {
      height: '150px',
      overflowY: 'scroll',
      marginBottom: '15px',
    },
  };

  const items = JSON.parse(order.items);

  return (
    <>
      <Card
        key={`${order.id}`}
        variant="outlined"
        style={styles.card}
      >
        <div>
          <h3>
            Order #{order.id}{' '}
            {order.seat_location && `- ${order.seat_location}`}
          </h3>
          <p>
            {order.phone_number &&
              `${order?.phone_number?.replace(
                /(\d{3})(\d{3})(\d{4})/,
                '($1) $2-$3',
              )}`}
          </p>
          <p>{order.tip > 0 && `Tip: $${order.tip.toFixed(2)}`}</p>
          <div style={styles.items}>
            {items.map((item) => (
              <FormGroup key={`item${item.id}`} row>
                <FormControlLabel
                  control={<Checkbox color="primary" />}
                  label={`${item.quantity} x ${item.name} ${
                    item.specialInstructions &&
                    `- ${item.specialInstructions}`
                  }`}
                />
              </FormGroup>
            ))}
          </div>
        </div>
        {handleAction && (
          <Button
            color="primary"
            variant="contained"
            onClick={handleAction}
          >
            {actionText}
          </Button>
        )}
      </Card>
    </>
  );
};

export default OrderCard;
