import React, { useState } from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  TextField,
} from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';
import NumberFormat from 'react-number-format';

const MenuItemCard = ({
  menuItem,
  cartItems,
  setCartItems,
  setSnackbarOpen,
}) => {
  const [quantity, setQuantity] = useState(0);
  const [specialInstructions, setSpecialInstructions] = useState('');
  const styles = {
    card: {
      alignItems: 'center',
      backgroundImage: `url(${menuItem.image})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      padding: '30px',
      border: '1px solid black',
    },
    name: {
      backgroundColor: 'white',
      textAlign: 'center',
      marginLeft: '25%',
      marginRight: '25%',
      borderRadius: '5px',
      border: '1px solid black',
      padding: '10px',
    },
    quantity: {
      border: 'none',
      fontSize: '20px',
      height: '25px',
      maxWidth: '25px',
      textAlign: 'center',
    },
    specialInstructions: {
      width: '65%',
      backgroundColor: 'white',
      margin: '5px',
      borderRadius: '5px',
    },
    whiteBackground: {
      backgroundColor: 'white',
      borderRadius: '5px',
      border: '1px solid black',
    },
  };

  const addToCart = (menuItem, quantity, specialInstructions) => {
    const itemToAdd = { ...menuItem, quantity, specialInstructions };
    setCartItems([...cartItems, itemToAdd]);
    setSnackbarOpen(true);
    setQuantity(0);
    setSpecialInstructions('');
  };

  return (
    <>
      <Card
        key={`${menuItem.name}${menuItem.id}`}
        variant="outlined"
        style={styles.card}
      >
        <h3 style={styles.name}>
          {menuItem.name} <br /> ${menuItem.price.toFixed(2)}
        </h3>
        <ButtonGroup style={styles.whiteBackground}>
          <Button
            disabled={quantity <= 0}
            onClick={() => setQuantity(quantity - 1)}
          >
            <Remove />
          </Button>
          <Button>
            <NumberFormat
              allowNegative={false}
              onChange={(e) => setQuantity(parseInt(e.target.value))}
              onFocus={(e) => {
                if (e.target.value == '0') {
                  e.target.value = '';
                }
              }}
              style={styles.quantity}
              type="tel"
              value={quantity}
            />
          </Button>
          <Button onClick={() => setQuantity(quantity + 1)}>
            <Add />
          </Button>
        </ButtonGroup>
      </Card>
      {quantity > 0 && (
        <>
          <p>{menuItem.description && menuItem.description}</p>
          <TextField
            variant="outlined"
            label="Special Instructions"
            value={specialInstructions}
            onChange={(e) => setSpecialInstructions(e.target.value)}
            multiline
            rows={4}
            style={styles.specialInstructions}
          />
          <br />
          <Button
            color="primary"
            variant="contained"
            onClick={() =>
              addToCart(menuItem, quantity, specialInstructions)
            }
          >
            Add to Cart
          </Button>
        </>
      )}
    </>
  );
};

export default MenuItemCard;
