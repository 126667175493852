const fetchVenue = async (venueUid) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/venues/${venueUid}`;
    const response = await fetch(url, {
      headers: {
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMDE1NyIsIm5hbWUiOiJDb25jZXNzaW9ubHkiLCJpYXQiOjE1MTYyMzkwMjJ9.YVx_aeq905WEf8SGP4r8ZpYmNzklvxpM1yYzkYfOqL0',
        'Content-Type': 'application/json',
      },
    });
    const json = await response.json();
    return json;
  } catch (error) {
    console.error(error);
  }
};

export { fetchVenue };
