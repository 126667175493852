import React from 'react';
import { InputAdornment, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const SearchBar = ({ handleSearch }) => (
  <TextField
    style={{ margin: '10px' }}
    label="Search"
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      ),
    }}
    onChange={(e) => handleSearch(e.target.value)}
  />
);

export default SearchBar;
