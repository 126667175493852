import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router';
import {
  CardElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { makeStyles } from '@material-ui/core/styles';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  SwipeableDrawer,
} from '@material-ui/core';
import { CircularProgress, TextField } from '@mui/material';
import {
  CheckCircle,
  ExpandMore,
  ShoppingCart,
} from '@material-ui/icons';
import IDChecker from './IDChecker';
import OrderTypeSelector from './OrderTypeSelector';
import { createOrder } from '../../lib/orders';

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: '100%',
  },
});

export default function Cart({
  allYouCanEat,
  cartItems,
  isDemo, // to disable submitting orders
  isHermitage, // For Cart # spot
  orderType,
  pickupInstructions,
  seatLocation,
  setOrderType,
  taxRate,
}) {
  const classes = useStyles();
  const [state, setState] = useState({ bottom: false });
  const [ccVerified, setCcVerified] = useState(false);
  const [ccOpen, setCcOpen] = useState(!ccVerified);
  const [errorMessage, setErrorMessage] = useState(null);
  const [idAccepted, setIdAccepted] = useState(false);
  const [idRequired, setIdRequired] = useState(false);
  const [idVerified, setIdVerified] = useState(false);
  const [idOpen, setIdOpen] = useState(!ccVerified);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [tip, setTip] = useState(0.0);
  const [cartNumber, setCartNumber] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const stripe = useStripe();
  const elements = useElements();
  const cardElement = elements.getElement(CardElement);
  const history = useHistory();

  let totalQuantity = 0;
  let itemTotal = 0;
  let salesTax = 0;
  let orderFee = allYouCanEat ? 0 : 3;
  let totalWithTax = 0;
  let totalWithTaxAndTip = 0;
  const calculateTotals = () => {
    cartItems.map((item) => {
      totalQuantity += parseInt(item.quantity);
      itemTotal += parseFloat(item.price) * parseInt(item.quantity);
    });
    salesTax = itemTotal * taxRate;
    totalWithTax = itemTotal + salesTax + orderFee;
    totalWithTaxAndTip = totalWithTax + tip;
  };
  calculateTotals();

  useEffect(() => {
    const isIdRequired = cartItems.some(
      (item) => item.id_required === true,
    );
    setIdRequired(isIdRequired);
  }, [cartItems]);

  const handleTipChange = (event) => {
    const newValue = event.target.value;
    // RegExp to test if newValue is empty, a number, or ends with a dot followed by 0, 1, or 2 digits
    if (/^(\d+\.?\d{0,2}|\.\d{0,2}|)$/.test(newValue)) {
      newValue === '' ? setTip(0) : setTip(parseFloat(newValue));
    }
  };

  const handleAccept = (event) => {
    event.preventDefault();
    setIdAccepted(true);
  };

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();
    setIsSubmitting(true);

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    // const cardElement = elements.getElement(CardElement);

    // Use your card Element with other Stripe.js APIs
    let orderData = {
      items: JSON.stringify(cartItems),
      order_type: orderType,
      phone_number: phoneNumber,
      seat_location: isHermitage
        ? `Cart ${cartNumber}`
        : seatLocation,
      store_id: cartItems[0].store_id,
      tip,
      total_in_cents: isHermitage
        ? totalWithTaxAndTip * 100
        : totalWithTax * 100,
      stripe_token: null,
    };

    try {
      if (!allYouCanEat) {
        try {
          const response = await stripe.createToken(cardElement);

          if (!response?.token?.id) {
            setErrorMessage(
              'There was an error processing your card.',
            );
            setIsSubmitting(false);
            return;
          }

          orderData = {
            ...orderData,
            stripe_token: response?.token?.id,
          };
        } catch (error) {
          console.error(error);
        }
      }

      const order = await createOrder(orderData);
      if (order?.message) {
        setErrorMessage(order.message);
      } else {
        history.push(`/orders/${order.uid}`);
      }
    } catch (error) {
      console.log('[error]', error);
    }
    setIsSubmitting(false);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor = 'bottom') => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      style={{ height: '100%', overflow: 'auto' }}
    >
      <List>
        <Button
          onClick={toggleDrawer('bottom', false)}
          style={{ float: 'right' }}
        >
          X
        </Button>
        <div style={{ height: '40px' }}></div>
        {/* <div>
          <OrderTypeSelector
            orderType={orderType}
            setOrderType={setOrderType}
          />
        </div> */}
        {cartItems.map((item) => (
          <ListItem button key={item.name}>
            <ListItemText
              primary={`${item.name} x ${item.quantity}`}
              secondary={`${item.specialInstructions}`}
            />
          </ListItem>
        ))}
        <ListItem>
          <ListItemText
            primary={`Subtotal: $${itemTotal.toFixed(2)}`}
          />
        </ListItem>
        {orderFee > 0 && (
          <ListItem>
            <ListItemText
              primary={`Order Fee: $${orderFee.toFixed(2)}`}
            />
          </ListItem>
        )}
        {salesTax > 0 && (
          <ListItem>
            <ListItemText
              primary={`Sales Tax (${(taxRate * 100).toFixed(
                2,
              )}%): $${salesTax.toFixed(2)}`}
            />
          </ListItem>
        )}
        {isHermitage && (
          <>
            <ListItem>
              <ListItemText
                primary={`Total Before Tip: $${totalWithTax.toFixed(
                  2,
                )}`}
              />
            </ListItem>
            <ListItem>
              <TextField
                label="Tip"
                onChange={handleTipChange}
                type="number"
                value={tip}
              />
            </ListItem>
          </>
        )}
        <ListItem>
          <ListItemText
            primary={`Grand Total: $${
              isHermitage
                ? totalWithTaxAndTip.toFixed(2)
                : totalWithTax.toFixed(2)
            }`}
          />
        </ListItem>
      </List>
      <Divider />
      <List>
        {isHermitage && (
          <ListItem>
            <TextField
              inputProps={{ maxLength: 3 }}
              label="Cart Number"
              onChange={(e) =>
                setCartNumber(parseInt(e.target.value) || '')
              }
              type="tel"
              value={cartNumber}
            />
          </ListItem>
        )}
        <ListItem>
          <TextField
            inputProps={{ maxLength: 10 }}
            label="Phone Number"
            onChange={(e) =>
              setPhoneNumber(parseInt(e.target.value) || '')
            }
            type="tel"
            value={phoneNumber}
          />
        </ListItem>
      </List>
      <Divider />
      {idRequired && (
        <List>
          <ListItem>
            <ListItemText
              style={{
                color: 'red',
                textAlign: 'center',
              }}
              primary={`You have selected items which legally require an ID to purchase. You will be required to show your ID to receive these items. If you fail to show a valid ID, you will not receive your item(s) and you will not be refunded.`}
            />
          </ListItem>
          <ListItem>
            <Button
              color="primary"
              disabled={idAccepted}
              onClick={handleAccept}
              size="large"
              style={{ width: '100%', color: 'white' }}
              variant="contained"
            >
              {idAccepted ? (
                <>
                  <CheckCircle style={{ fill: 'green' }} />
                  Accepted
                </>
              ) : (
                `I Accept`
              )}
            </Button>
          </ListItem>
        </List>
      )}
      {/* old ID Checker */}
      {/* {idRequired && (
        <List>
          <ListItem button key={'ID'}>
            <Accordion
              expanded={idOpen}
              onChange={() => setIdOpen(!idOpen)}
              style={{ width: '100%' }}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Grid item xs={11}>
                  <ListItemText primary={'ID'} />
                </Grid>
                <Grid item xs={1}>
                  <ListItemText
                    secondary={
                      idVerified && (
                        <CheckCircle style={{ fill: 'green' }} />
                      )
                    }
                  />
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <IDChecker
                  onIdVerified={() => {
                    setIdOpen(false);
                    setIdVerified(true);
                  }}
                />
              </AccordionDetails>
            </Accordion>
          </ListItem>
        </List>
      )} */}
      {!allYouCanEat && (
        <List>
          <ListItem button key={'Credit Card'}>
            <Accordion
              expanded={ccOpen}
              onChange={() => setCcOpen(!ccOpen)}
              style={{ width: '100%' }}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Grid item xs={11}>
                  <ListItemText primary={'Credit Card'} />
                </Grid>
                <Grid item xs={1}>
                  <ListItemText
                    secondary={
                      ccVerified && (
                        <CheckCircle style={{ fill: 'green' }} />
                      )
                    }
                  />
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ width: '100%' }}>
                  <CardElement
                    onChange={(e) => {
                      if (e.complete) {
                        setCcVerified(true);
                        setCcOpen(false);
                      }
                    }}
                    options={{
                      style: {
                        base: {
                          fontSize: '16px',
                          color: '#424770',
                          '::placeholder': {
                            color: '#aab7c4',
                          },
                        },
                        invalid: {
                          color: '#9e2146',
                        },
                      },
                    }}
                  />
                </div>
              </AccordionDetails>
            </Accordion>
          </ListItem>
        </List>
      )}
      <Divider />
      <List>
        {errorMessage && (
          <ListItem>
            <ListItemText
              style={{
                color: 'red',
                textAlign: 'center',
              }}
              primary={errorMessage}
            />
          </ListItem>
        )}
        {pickupInstructions && (
          <ListItem>
            <ListItemText
              style={{
                color: 'green',
                textAlign: 'center',
              }}
              primary={pickupInstructions}
            />
          </ListItem>
        )}
        <ListItem>
          <Button
            color="primary"
            disabled={
              isDemo ||
              isSubmitting ||
              (!allYouCanEat && !ccVerified) ||
              !orderType ||
              (idRequired && !idAccepted) ||
              (isHermitage && !cartNumber) ||
              !phoneNumber ||
              phoneNumber?.toString().length !== 10
            }
            onClick={handleSubmit}
            size="large"
            style={{ width: '100%', color: 'white' }}
            variant="contained"
          >
            {isSubmitting ? (
              <CircularProgress color="primary" />
            ) : (
              `Submit Order`
            )}
          </Button>
        </ListItem>
      </List>
    </div>
  );

  return (
    <div
      style={{
        position: 'fixed',
        bottom: 0,
        backgroundColor: 'primary',
        zIndex: 1000,
        width: '100%',
      }}
    >
      <>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={toggleDrawer('bottom', true)}
          startIcon={<ShoppingCart />}
          style={{ padding: '15px' }}
        >
          {`Cart (${totalQuantity} items)`}
        </Button>
        <SwipeableDrawer
          anchor={'bottom'}
          open={state['bottom']}
          onClose={toggleDrawer('bottom', false)}
          onOpen={toggleDrawer('bottom', true)}
        >
          {list('bottom')}
        </SwipeableDrawer>
      </>
    </div>
  );
}
