const completeOrder = async (order) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/orders/${order.id}`;
    const completedAt = new Date();
    const response = await fetch(url, {
      method: 'PATCH',
      headers: {
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMDE1NyIsIm5hbWUiOiJDb25jZXNzaW9ubHkiLCJpYXQiOjE1MTYyMzkwMjJ9.YVx_aeq905WEf8SGP4r8ZpYmNzklvxpM1yYzkYfOqL0',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        completed_at: completedAt,
        completed_time: completedAt - new Date(order.createdAt),
        status: 'complete',
      }),
    });
    const json = await response.json();
    return json;
  } catch (error) {
    console.error(error);
  }
};

const updateOrderStatus = async (order, newStatus) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/orders/${order.id}`;
    const response = await fetch(url, {
      method: 'PATCH',
      headers: {
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMDE1NyIsIm5hbWUiOiJDb25jZXNzaW9ubHkiLCJpYXQiOjE1MTYyMzkwMjJ9.YVx_aeq905WEf8SGP4r8ZpYmNzklvxpM1yYzkYfOqL0',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        status: newStatus,
      }),
    });
    const json = await response.json();
    return json;
  } catch (error) {
    console.error(error);
  }
};

const createOrder = async (orderData) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/orders`;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMDE1NyIsIm5hbWUiOiJDb25jZXNzaW9ubHkiLCJpYXQiOjE1MTYyMzkwMjJ9.YVx_aeq905WEf8SGP4r8ZpYmNzklvxpM1yYzkYfOqL0',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...orderData,
        createdAt: new Date(),
        status: 'active',
        uid: generateUid(),
      }),
    });
    const json = await response.json();
    return json;
  } catch (error) {
    console.error(error);
  }
};

const fetchOrder = async (orderUid) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/orders/${orderUid}`;
    const response = await fetch(url, {
      headers: {
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMDE1NyIsIm5hbWUiOiJDb25jZXNzaW9ubHkiLCJpYXQiOjE1MTYyMzkwMjJ9.YVx_aeq905WEf8SGP4r8ZpYmNzklvxpM1yYzkYfOqL0',
        'Content-Type': 'application/json',
      },
    });
    const json = await response.json();
    return json;
  } catch (error) {
    console.error(error);
  }
};

const generateUid = () => {
  return `${new Date().getTime()}`;
};

const getWaitTime = async (storeId) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/orders?storeId=${storeId}&status=complete`;
    const response = await fetch(url);
    const orders = await response.json();
    if (!orders.length > 0) {
      return null;
    }
    const times = orders.map((order) => order.completedTime);
    let totalInMs = 0;
    times.forEach((time) => (totalInMs += time));
    const averageInMs = totalInMs / times.length;
    return msToMS(averageInMs);
  } catch (error) {
    console.error(error);
  }
};

const msToMS = (ms) => {
  if (ms < 60000) {
    return `Less than 1 minute`;
  }

  // 1- Convert to seconds:
  let seconds = ms / 1000;
  seconds = seconds % 3600; // seconds remaining after extracting hours
  // 2- Extract minutes:
  const minutes = parseInt(seconds / 60); // 60 seconds in 1 minute
  // 3- Keep only seconds not extracted to minutes:
  seconds = seconds % 60;
  return `About ${minutes} - ${minutes + 5} minutes`;
};

export {
  completeOrder,
  createOrder,
  fetchOrder,
  getWaitTime,
  updateOrderStatus,
};
