const demoMenuItems = [
  {
    name: 'All Beef Hotdog',
    description: '',
    price: 6,
    image:
      'https://www.ballparkbrand.com/sites/default/files/Hero_Dog_0.png',
    store_id: 6,
    ayce: false,
    active: true,
    id_required: false,
  },
  {
    name: 'Double Burger',
    description: `Two burger patties with choice of cheese served with lettuce, tomato, pickle, and onion on a
toasted bun.`,
    price: 9,
    image:
      'https://olo-images-live.imgix.net/77/77a9cb0ece994bfa844c0a88ee136942.jpg?auto=format%2Ccompress&q=60&cs=tinysrgb&w=459&h=291&fit=crop&fm=png32&s=606a5d6c6deba1669e1730a2bfca20da',
    store_id: 5,
    ayce: false,
    active: true,
    id_required: false,
  },
  {
    name: 'Grilled Chicken Sandwich',
    description: `Grilled chicken breast with choice of cheese served with lettuce, tomato, pickle, and onion on a
toasted bun.`,
    price: 8.5,
    image:
      'https://easychickenrecipes.com/wp-content/uploads/2022/10/Featured-grilled-chicken-sandwich-1-of-1.jpg',
    store_id: 5,
    ayce: false,
    active: true,
    id_required: false,
  },
  {
    name: 'Candy',
    description:
      "Choice of KitKat, M&Ms, Milky Way, PayDay, Butterfinger, or Reese's",
    price: 2.25,
    image:
      'https://assets.rebelmouse.io/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpbWFnZSI6Imh0dHBzOi8vYXNzZXRzLnJibC5tcy8zMjEwNjg2My9vcmlnaW4uanBnIiwiZXhwaXJlc19hdCI6MTc0MTU4NDU5OH0.UQ1FQbFE1MC8t3er7lGVMpfYq41HI7oZNzkisxAdDRc/img.jpg?width=2000&height=1500&quality=85&coordinates=0%2C187%2C0%2C188',
    store_id: 5,
    ayce: false,
    active: true,
    id_required: false,
  },
  {
    name: 'Chips',
    description:
      "Choice of Lay's Classic, Pretzels, SunChips, Doritos",
    price: 1.95,
    image:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/6/69/Potato-Chips.jpg/800px-Potato-Chips.jpg',
    store_id: 5,
    ayce: false,
    active: true,
    id_required: false,
  },
  {
    name: 'Domestic Beer',
    description:
      'Choice of Miller Light, Coors Light, Michelob Ultra, Yuengling, Bud Light, Budweiser',
    price: 5,
    image:
      'https://ipcdn.freshop.com/resize?url=https://images.freshop.com/00018200250019/5ea82d72062412023e9022b9e942734b_large.png&width=256&type=webp&quality=80',
    store_id: 6,
    ayce: false,
    active: true,
    id_required: true,
  },
];

const fetchMenuItems = async (storeUid) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/menu_items?store_id=${storeUid}`;
    const response = await fetch(url, {
      headers: {
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMDE1NyIsIm5hbWUiOiJDb25jZXNzaW9ubHkiLCJpYXQiOjE1MTYyMzkwMjJ9.YVx_aeq905WEf8SGP4r8ZpYmNzklvxpM1yYzkYfOqL0',
        'Content-Type': 'application/json',
      },
    });
    const json = await response.json();
    return json;
  } catch (error) {
    console.error(error);
  }
};

const updateMenuItem = async (id, update) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/menu_items/${id}`;
    const response = await fetch(url, {
      method: 'PATCH',
      headers: {
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMDE1NyIsIm5hbWUiOiJDb25jZXNzaW9ubHkiLCJpYXQiOjE1MTYyMzkwMjJ9.YVx_aeq905WEf8SGP4r8ZpYmNzklvxpM1yYzkYfOqL0',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(update),
    });
    const json = await response.json();
    return json;
  } catch (error) {
    console.error(error);
  }
};

export { demoMenuItems, fetchMenuItems, updateMenuItem };
