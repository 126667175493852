import React from 'react';
import { Grid, Paper } from '@material-ui/core';

const OrgCard = ({ classes, organization, seatLocation, venue }) => {
  return (
    <Paper className={classes.orgCard}>
      <Grid item xs={12}>
        <img
          src={organization.logo}
          alt="organization logo"
          className={classes.logo}
          style={{ borderRadius: '5px' }}
        />
      </Grid>
      <h4>Welcome to {venue.name}!</h4>
      <h2>{organization.name}</h2>
      <h3>Mobile Ordering</h3>
      {organization.mascot && (
        <h3>Home of the {organization.mascot}</h3>
      )}
      {seatLocation && <h4>{seatLocation}</h4>}
      <h5>
        Powered by <a href="https://concessionly.co">Concessionly</a>
      </h5>
    </Paper>
  );
};

export default OrgCard;
