import React, { useEffect, useRef, useState } from 'react';
import useSound from 'use-sound';
import NewOrderSound from './new_order.mp3';
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  makeStyles,
  Switch,
  Tab,
  Tabs,
  ThemeProvider,
  Typography,
} from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { createMuiTheme } from '@material-ui/core/styles';
import SearchBar from '../../components/SearchBar';
import OrderCard from './OrderCard';
import {
  fetchMenuItems,
  fetchStore,
  updateMenuItem,
  updateStore,
} from '../../lib';
import { completeOrder, updateOrderStatus } from '../../lib/orders';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const StoreView = (props) => {
  const { storeUid } = props.match.params;
  const [organization, setOrganization] = useState({
    primaryColor: '#fff',
    secondaryColor: '#000',
    logo: '',
    name: '',
    mascot: '',
  });

  const [activeOrders, setActiveOrders] = useState([]);
  const [inProgressOrders, setInProgressOrders] = useState([]);
  const [allowAccess, setAllowAccess] = useState(false);
  const [completedOrders, setCompletedOrders] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [soundEnabled, setSoundEnabled] = useState(false);
  const [store, setStore] = useState({ name: '', active: true });
  const [tabValue, setTabValue] = useState(0);

  const [playSound] = useSound(NewOrderSound);
  const soundRef = useRef(null);

  useEffect(() => {
    let password = prompt('Enter password');

    setAllowAccess(
      password === 'Hoots2022' ||
        password === 'Presidents2023' ||
        password === 'Generals2023' ||
        password === 'Cornbelters2023',
    );

    const fetchData = async () => {
      const selectedStore = await fetchStore(storeUid);
      setStore(selectedStore);
      setOrganization(selectedStore.organization);
      let newActiveOrders = [];
      let newInProgressOrders = [];
      let newCompletedOrders = [];
      selectedStore.orders.map((order) => {
        switch (order.status) {
          case 'active':
            newActiveOrders.push(order);
            break;
          case 'in progress':
            newInProgressOrders.push(order);
            break;
          default:
            newCompletedOrders.push(order);
        }
      });
      if (newActiveOrders.length > 0) {
        // TODO: way to turn sound back off
        soundRef?.current?.click();
      }
      setActiveOrders(newActiveOrders);
      setInProgressOrders(newInProgressOrders);
      setCompletedOrders(newCompletedOrders);
      const menuItems = await fetchMenuItems(storeUid);
      setMenuItems(menuItems);
    };

    fetchData();
    setInterval(() => {
      fetchData();
    }, 15000);
  }, []);

  const handleInProgress = async (inProgressOrder) => {
    await updateOrderStatus(inProgressOrder, 'in progress');
    setActiveOrders(
      activeOrders.filter((order) => order.id !== inProgressOrder.id),
    );
    setInProgressOrders([
      { ...inProgressOrder, status: 'in progress' },
      ...inProgressOrders,
    ]);
  };

  const handleComplete = async (completedOrder) => {
    await completeOrder(completedOrder);
    setActiveOrders(
      activeOrders.filter((order) => order.id !== completedOrder.id),
    );
    setCompletedOrders([
      { ...completedOrder, status: 'complete' },
      ...completedOrders,
    ]);
  };

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: `${organization.primaryColor}`,
      },
      secondary: {
        main: `${organization.secondaryColor}`,
      },
    },
  });

  const useStyles = makeStyles((theme) => ({
    card: {
      backgroundColor: `${organization.primaryColor}`,
      color: `${organization.secondaryColor}`,
      padding: '10px',
    },
    logo: {
      maxWidth: '100px',
    },
  }));

  const classes = useStyles();

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleToggleMenuItem = async (event, value, menuItem) => {
    const updatedItem = await updateMenuItem(menuItem.id, {
      active: value === 'true' ? true : false,
    });
    const newItems = menuItems.map((item) => {
      if (item.id === updatedItem.id) {
        return updatedItem;
      }
      return item;
    });
    setMenuItems(newItems);
  };

  const handleToggleStore = async (event, value, store) => {
    const updatedStore = await updateStore(store.uid, {
      active: value === 'true' ? true : false,
    });
    setStore(updatedStore);
  };

  if (!allowAccess) {
    return <p>Access Denied</p>;
  }

  return (
    <ThemeProvider theme={theme}>
      <Grid container>
        <Grid item xs={12}>
          <h2>{store.name}</h2>
          <div
            style={{
              alignItems: 'center',
              border: '1px solid black',
              backgroundColor: store.active ? 'white' : 'red',
            }}
          >
            <p>Allow Mobile Orders</p>
            <ToggleButtonGroup
              color="primary"
              value={store.active ? 'true' : 'false'}
              exclusive
              onChange={(event, value) =>
                handleToggleStore(event, value, store)
              }
            >
              <ToggleButton value="true">Yes</ToggleButton>
              <ToggleButton value="false">No</ToggleButton>
            </ToggleButtonGroup>
          </div>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="basic tabs example"
          >
            <Tab label="Active Orders" />
            <Tab label="In Progress Orders" />
            <Tab label="Completed Orders" />
            <Tab label="Menu Items" />
          </Tabs>
          <TabPanel value={tabValue} index={0}>
            <h3>Active Orders</h3>
            <FormControlLabel
              control={
                <Switch
                  label={{
                    'aria-label': 'Turn on Sound Notifications',
                  }}
                  onClick={() => playSound()}
                />
              }
              label="Turn on Sound Notifications"
            />
            <Button
              onClick={() => playSound()}
              ref={soundRef}
              sx={{ display: 'none' }}
            />
            {activeOrders.length < 1 && (
              <p>No current orders to display.</p>
            )}
            <Grid container>
              {activeOrders.map((order) => (
                <Grid item key={order.id} md={4} sm={6} xs={12}>
                  <OrderCard
                    actionText="Start Order"
                    handleAction={() => handleInProgress(order)}
                    {...order}
                  />
                </Grid>
              ))}
            </Grid>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <h3>In Progress Orders</h3>
            {inProgressOrders.length < 1 && (
              <p>No in progress orders to display.</p>
            )}
            <Grid container>
              {inProgressOrders.map((order) => (
                <Grid item key={order.id} md={4} sm={6} xs={12}>
                  <OrderCard
                    actionText="Complete Order"
                    handleAction={() => handleComplete(order)}
                    {...order}
                  />
                </Grid>
              ))}
            </Grid>
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <h3>Completed Orders</h3>
            {completedOrders.length < 1 && (
              <p>No current orders to display.</p>
            )}
            <Grid container>
              {completedOrders.map((order) => (
                <Grid item key={order.id} md={4} sm={6} xs={12}>
                  <OrderCard {...order} />
                </Grid>
              ))}
            </Grid>
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            <h3>Menu Items</h3>
            <Grid container>
              {menuItems.map((menuItem) => (
                <Box key={`menuItem${menuItem.id}`}>
                  <Grid item xs={12}>
                    <div
                      style={{
                        alignItems: 'center',
                        border: '1px solid black',
                        backgroundColor: menuItem.active
                          ? 'white'
                          : 'red',
                      }}
                    >
                      <p>{menuItem.name}</p>
                      <ToggleButtonGroup
                        color="primary"
                        value={menuItem.active ? 'true' : 'false'}
                        exclusive
                        onChange={(event, value) =>
                          handleToggleMenuItem(event, value, menuItem)
                        }
                      >
                        <ToggleButton value="true">
                          Active
                        </ToggleButton>
                        <ToggleButton value="false">
                          Inactive
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </div>
                  </Grid>
                </Box>
              ))}
            </Grid>
          </TabPanel>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default StoreView;
